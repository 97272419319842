import { useAuth0 } from '@auth0/auth0-react'
import { Button, Flex, Suspender, Text } from '@mm/company-ui'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useFeatureFlags } from '../modules/featureFlags'
import { Frame, FrontFrame } from '../modules/navigation'

const LandingPage = () => {
  const router = useRouter()
  const { user, isLoading } = useAuth0()
  const { prototypeHome, redirectRootToMeetings, homeDashboard } = useFeatureFlags()

  const ldClient = useLDClient()

  useEffect(() => {
    if (user && !isLoading && ldClient) {
      const redirect = async () => {
        await ldClient?.waitUntilReady()
        void router.replace(
          homeDashboard
            ? '/home'
            : redirectRootToMeetings
            ? '/meetings'
            : prototypeHome
            ? '/prototype/home'
            : '/actions',
        )
      }
      void redirect()
    }

    // We do not care if router changes, only need it to push
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLoading, ldClient])

  if (isLoading) {
    return (
      <Frame>
        <Suspender />
      </Frame>
    )
  }

  return user == null ? (
    <FrontFrame>
      <Flex
        sx={{
          width: '100%',
          height: '100%',
          justifyContent: ['start', 'space-between'],
          flexDirection: ['column', 'row'],
          alignItems: ['start', 'center'],
        }}
      >
        <div sx={{ width: ['100%', '500px'] }}>
          <div
            sx={{ borderBottom: '1px solid', borderBottomColor: 'greyscale.100', paddingBottom: 2, marginBottom: 4 }}
          >
            <Text
              variant="jumbo"
              as="h1"
              sx={{
                marginBottom: 4,
                fontWeight: 'bold',
                fontSize: [4, 6],
                maxWidth: ['450px', '500px'],
              }}
            >
              We&apos;re building your Company Operating System
            </Text>
            <Text as="p" sx={{ color: 'greyscale.700', fontSize: [0, 2] }}>
              Your team&apos;s Actions, 1-1s, and OKRs.
              <br />
              All in one place.
              <br />
              Follow the method.
            </Text>
          </div>
          <Text as="p" sx={{ color: 'greyscale.700', fontSize: [0, 2], marginBottom: 2 }}>
            We&apos;re hiring.
            <br />
            Come join our team!
          </Text>
          <Button onClick={() => window.open('/careers', '_blank')} variant="accent">
            See open roles
          </Button>
        </div>
        <div
          sx={{
            width: ['100%', '700px'],
            marginTop: [4, -8],
            marginBottom: [4, 0],
            marginX: [0, 8],
            position: 'relative',
            zIndex: -1,
          }}
        >
          <Image alt="Landing Page Image" src="/landing.svg" layout="responsive" height={690} width={554} />
        </div>
      </Flex>
    </FrontFrame>
  ) : null
}

export default LandingPage
